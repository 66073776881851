/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;
function restoreWishlistButtonState() {
    $("#addToWishlistButton").html(button_add_to_wishlist_initial_label);
    $("#addToWishlistButton")
        .removeClass("existToWishlistButton")
        .addClass("addToWishlistButton");
    $("#addToWishlistButton")
        .attr("onclick", button_add_to_wishlist_initial_click_event)
        .attr("href", "#");
}

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist(evt) {
    var product_id = $("#hdn_product_id").val();
    var product_refext = $("#hdn_product_refext").val();

    var color_id = $("#couleurProd").val();
    var size_id = $("#tailleProd").val();
    var need_color_id = ($("#couleurProd").length != 0 && $("#couleurProd").val() != 'none');
    var need_size_id = ($("#tailleProd").length != 0 && $("#tailleProd").val() != 'none');

    if (parseInt($('#in_basket').val()) === 1) {
        var id_select = $(this).attr('class');
        product_id = id_select.split('product_towish basketToWishlist id_');

        color_id = "#couleurProd_"+product_id;
        color_id = color_id.replace(',','');
        color_id = $(color_id).val();
        size_id = "#tailleProd_"+product_id;
        size_id = size_id.replace(',','');
        size_id = $(size_id).val();

        need_color_id = "#couleurProd_"+product_id;
        need_color_id = need_color_id.replace(',','');
        need_color_id = $(need_color_id).val();
        need_size_id = "#tailleProd_"+product_id;
        need_size_id = need_size_id.replace(',','');
        need_size_id = $(need_size_id).val();

        need_color_id =  ($(need_color_id).length != 0 && $(need_color_id).val() != 'none');
        need_size_id =  ($(need_size_id).length != 0 && $(need_size_id).val() != 'none');
    }
    var is_model = $("#hdn_is_model").val();

    let hasBasket = (parseInt($('#in_basket').val()) === 1);
    let btnWishlist = (hasBasket ? $("#addToWishlistButton_" + product_id) : $("#addToWishlistButton"));

    var datas = {};
    datas.product_id = product_id;
    datas.size_id = size_id;
    datas.color_id = color_id;
    datas.need_color_id = need_color_id;
    datas.need_size_id = need_size_id;
    datas.is_model = is_model;

    // On regarde si le produit est deja dans la wishlist
    if (!btnWishlist.hasClass('existToWishlistButton')) {

        evt.preventDefault();

        if (typeof (button_add_to_wishlist_initial_label) == "undefined") {

            button_add_to_wishlist_initial_label = $("#addToWishlistButton span").html();
        }

        if (!product_id || typeof product_id == "undefined") {

            return false;
        }

        if (hasBasket) {
            datas.product_id = datas.product_id[1];
        }

        from_range = (!!document.getElementById('lightbox_achat_express'));

        $.post(path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(), datas, function (resp) {

            $("#addToWishlistButton").parent().removeClass('loading');

            if (resp.error) {

                if (resp.message == "login_required") {
                    var html =
                            '<div class="box_wishlist wishAlert">' +
                            resp.result.error_message +
                            '<div class="btn_container">' +
                            '<div class="button primary" href="#" onclick="wishlistRedirectLogin();">' +
                            resp.result.btn_connexion +
                            "</div>" +
                            "</div>" +
                            "</div>";

                    if ($('#lightbox_achat_express').length) {

                        /*
                        $('#lightbox_achat_express').fadeOut("fast", function () {
                            $('#shad').css('display', 'none');
                        });
                        */
                        closeMultiShad('lightbox_achat_express');
                    }

                    $("#abox").addClass("wishConnection");
                    alert(html);
                    $("#abox .form_submit").css("display", "none");

                } else if (resp.message != "error") {
                    $(".disabledBtnTooltip").addClass('actif');
                } else {
                    alert(Translator.translate(resp.result.error_message));
                    return false;
                }
            } else {
                //evenement DataLayer
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'addToWishlist',
                        'products': {
                            'product_id': product_id,
                            'product_refext': product_refext,
                        }
                    });
                }
                if (hasBasket) {
                    var btn_wish = "#addToWishlistButton_"+product_id;
                    var span_wish =  btn_wish+ " span";
                    var del_product = "#del_product_"+product_id;

                    btn_wish = btn_wish.replace(',','');
                    span_wish = span_wish.replace(',','');
                    del_product = del_product.replace(',','');

                    $(span_wish).html(resp.result.button_label);
                    $(btn_wish).removeClass("addToWishlistButton").addClass("existToWishlistButton");

                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    $(del_product)[0].click();
                } else {
                    $("#addToWishlistButton span").html(resp.result.button_label);
                    $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");

                    wishlist.push(resp.result.wishlist_row_array);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    /* Update wishlist top */
                    var response_basket = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
                    $("#wishlist_top").html(response_basket);
                    $("#wishlist_top").addClass('hover');
                }

            }
        });
    } else {

        if (datas.size_id !== "" && btnWishlist.hasClass('existToWishlistButton')) {
            // Ajax remove wishlist
            $.ajax({
                url: path_relative_root + "ajax_remove_from_wishlist.php",
                type: 'post',
                data: datas
            })
                .done(function (resp) {
                    if (!resp.error) {
                        btnWishlist.removeClass("existToWishlistButton");
                        changeBtnAddWishlist(false, idElt ?? product_id);
                    }
                });
        } else if (btnWishlist.hasClass('existToWishlistButton')) {
            if (datas.size_id === "") {
                alert(Translator.translate('error_suppr_wishlist_taille'))
            }
            if (datas.color_id === "") {
                alert(Translator.translate('error_suppr_wishlist_couleur'));
            }
        }
    }
}

function checkProductInWishlist(productId) {
    var prod_id;
    if (productId != undefined && productId) {
        prod_id = productId;
    } else if ($("input[name='produit_id']").length) {
        prod_id = $("input[name='produit_id']").val();
    } else if ($("produit_id")) {
        prod_id = $("produit_id").val();
    }

    var couleurProd = $("#couleurProd");
    if (couleurProd.length) {
        couleurProd = $("#couleurProd").val();
    } else {
        couleurProd = 0;
    }
    var tailleProd = $("#tailleProd");
    if (tailleProd.length) {
        tailleProd = $("#tailleProd").val();
    } else {
        tailleProd = 0;
    }

    if (typeof prod_id != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data:{'product_id': prod_id, 'size_id': tailleProd, 'color_id': couleurProd},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 * Aurelien Renault
 */
function changeBtnAddWishlist(in_wishlist) {
    var btn = $("#addToWishlistButton");
    if (in_wishlist) {
        btn.removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

function wishlistRedirectLogin() {
    $.get(
        path_relative_root + "ajax_add_to_wishlist.php?login_required=1",
        function() {
            document.location.href = path_relative_root + create_link("connexion_login") + "?from=front";
        }
    );
}

function addToWishlistUpdateHeader(nr) {

    if (nr == 0) {

        $(".rolloverTxt.wishlist .monpanier").html("0");
    } else {

        $(".rolloverTxt.wishlist .monpanier").html(nr);
    }
}

function scanForChanges() {
    var totalPrice = 0;
    var totalPoints = 0;
    selectedProducts = [];
    wishlistProductData = [];
    selectedProductsObj = {};

    $(".checkbox_select_product").each(function() {
        var element = $(this);
        var id = element.attr("data-id");

        /**
         * Sélectionne le bloc .product_ctn
         */
        var productCnt = $(this).closest('.product_ctn');

        if (element.is(":checked")) {
            var price = parseFloat(element.attr("data-price"));
            var ref = element.attr('data-ref');

            if (price) {
                totalPrice += price;
            }

            wishlistProductData.push({
                productId: element.attr("data-produit-id"),
                colorId: element.attr("data-color-id"),
                sizeId: element.attr("data-size-id"),
            });

            selectedProductsObj.selectedProducts = JSON.stringify(wishlistProductData);
            selectedProducts.push(id);

            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }

            /**
             * Ajoutez la classe
             * 'checked'
             */
            productCnt.addClass('checked');
            element.next('label').html(Translator.translate("unselect_product"));
        } else {
            /**
             * Retirez la classe
             * 'checked'
             */
            if(productCnt.hasClass('checked')) {
                productCnt.removeClass('checked');
                element.next('label').html(Translator.translate("select_product"));
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    var noCentClass = "";
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + " :";
            priceSpan.html(
                '<span id="total_price">0<span class="devise">&euro;</span>'
            );
            break;
        case 1:
            text = $texts[1];
        default:
            if (text == "") text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = "";
                noCentClass = "no_cents";
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = "" + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + "0";
                }
            }

            priceSpan.html(
                '<span id="total_price">' +
                    pricefloor +
                    '<span class="decimal_price ' + noCentClass +'">,' +
                    decimal +
                    "</span><span class=\"devise\">&euro;</span></span>"
            );
            txt =
                '<span class="wishlist_count">' +
                selectedProducts.length +
                "</span> " +
                text +
                " :";
    }

    $(".txt", "#nrArticlesSelected").html(txt);
}

function addProductToCart(wpId, pId, sId, cId) {
    $.post(
        path_relative_root + create_link("ajax_wishlist_add_product_to_cart"),
        {
            product_id: pId,
            size_id: sId,
            color_id: cId,
        },
        function(resp) {
            if (resp == "ok") {
                document.location.href =
                    path_relative_root + create_link("order_basket");
            } else {
                if ($("#ckwp_" + wpId).attr("pointscadeau")) {
                    alert(Translator.translate("err_no_points"));
                }
            }
        }
    );
}

/**
 * Ferme une popup par rapport à son nom et ferme aussi le shad
 * Utilisation : #sendwishlistbox qui est généré dinamiquement dans wishlist_index.php
 */
function closePopup(popup_id, shad_id) {
    if (shad_id == undefined) {
        shad_id = "shad";
    }
    var $popup = $("#" + popup_id),
        $shad = $("#" + shad_id);

    $shad.unbind("click.popup");
    $popup.slideUp("slow", function() {
        $shad.fadeOut("slow", function() {
            if ($("#wrapper_sendwishlist").hasClass("sended")) {
                $("#sendwishlistbox").remove();
            }
        });
    });
}

/**
 * Utiliser pour ajouter un magasin dans ses favoris (boutique_details)
 */
function addToMyFavorites() {
    var is_favorite_elem = document.getElementById("is_favorite");

    var self = $(this);
    var magasin_id = self.attr("data-magasinid");
    var client_id = self.attr("data-clientid");
    var action = self.attr("data-action");

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if (action == "destroy") {
        var act = "del";
    } else {
        // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
        if (is_favorite_elem.value == "1") {
            var act = "del";
        } else {
            var act = "add";
        }
    }

    $.ajax({
        url: path_relative_root + "ajax_manage_favorites_stores.php",
        type: "post",
        dataType: "html",
        data: "act=" + act + "&magasin_id=" + magasin_id,
        success: function(response) {
            if (response) {
                // Destruction du bloc
                if (action == "destroy") {
                    self.slideUp("slow", function() {
                        self.remove();
                    });
                } else {
                    if (act == "add") {
                        var libelle =
                            "<span>" +
                            Translator.translate("delete_to_favorites") +
                            "</span>";

                        is_favorite_elem.value = 1;
                    } else {
                        var libelle =
                            "<span>" +
                            Translator.translate("add_to_favorites") +
                            "</span>";

                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        },
    });
}

//Function to load previous selected Size product in basket
function loadSelectedSize(cnt_sizes) {

    var sizes = document.getElementsByName('itm_size');

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_size_selected.php',
        success: function (response) {

            //on refresh checkSubmitState si cnt_size == 1
            if ($('#ligne_pointure .choices_list')) {
                setTimeout(() => {
                if ($('#ligne_pointure .choices_list .form_itm.check.size').length == 1) {
                    checkSubmitState();
                }}, 500);
            }
            if (response && response.length > 0) {
                response = $.parseJSON(response);
                var selected_size = (response.selected_size);
                var current_size;
                var current_element;
                var sizeProd = document.getElementById("tailleProd");
                var html = '';

                for (var i = 0; i < sizes.length; i++) {
                    current_element = sizes.item(i).value;
                    if ((selected_size === current_element && current_element !== null) && (!sizes.item(i).classList.contains("disabled"))) {
                        sizes.item(i).checked = true;
                        sizeProd.value = current_element;
                        enableAddCart();
                    }
                }
            }
        }
    });
}

function addWishNoConnect(div_id, user, reference, is_alliance, code_couleur, idprod, wishlist) {
    if (user) {
        ajax_achat_express(reference, is_alliance, code_couleur, idprod, wishlist);
    } else {
        addWishListNoConnect(div_id);
    }
}

function ajax_achat_express(reference, is_alliance, code_couleur, idprod, wishlist) {
    var arg = '';

    if (wishlist !== undefined) {

        arg = '&wishlist=true';
    }
    var self = $(this);

    $.ajax({
        type: 'get',
        url: path_relative_root+'ajax_achat_express.php?ref='+reference+'&couleur='+code_couleur+arg,
        success: function( response ){
            var $lighbox = $( '#lightbox_achat_express' );
            if(!$lighbox.length){
                $('body').append('<div id="lightbox_achat_express"></div>');
                $lighbox = $( '#content_achat_express' );
            }
            $lighbox
                .html( '<div class="express_response"><div class="closeBtn" onclick="closeMultiShad(\'lightbox_achat_express\')">'+ '<span>X</span>' +'</div>'+ response +'</div>' )
                .appendTo( 'body' )
            ;

            // Due to lightbox sizeguide + lightbox express buy --> redirect on sizeguide page on cta click
            $lighbox.find('#sizeguide').attr('onclick', '');
            $lighbox.find('#sizeguide').on('click', function() {
                document.location.href = path_relative_root + create_link('guide_taille')
            });

            // Update color name besides COLOR label
            var currentSelectedColor = $('#currentSelectedColor');
            var couleurProd = $('.prodColor:checked');
            currentSelectedColor.html(couleurProd.data('nom'));

            openMultiShad('lightbox_achat_express');

            // Reload Alerte stock if present in lightbox
            if (!$(".w-alertestock-form").length) {
                new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
            }

            // event sur le bouton d'ajout à la wishlist
            //$("#addToWishlistButton").on("click", addToWishlist);


            // Pour les event des tooltips "Veuillez selectionner une taille"
            eventButton();
            setTimeout(function () {

                checkSubmitState();
            }, 500);
        }
    });
}

function addWishListNoConnect(prod_id) {
    $.post(
        path_relative_root + "ajax_add_to_wishlist.php?time=" + Date.now(),

        function (resp) {
            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox")

            var html =
                '<div class="light_title"></div>';

            if (resp.error) {
                if ($alertboxLogin.length == 0) {
                    $alertboxLogin = $(
                        '<div id="abox">' + html + "</div>"
                    );
                }

                if (resp.message == "login_required") {
                    if ($('#abox .btn_container.btn_cnx').length == 0) {
                        $alertboxBtns
                            .append($(
                                '<div class="btn_container btn_cnx">' +
                                    '<button class="button primary"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + prod_id + '\');">' +
                                        '<span>' +
                                            resp.result.btn_connexion +
                                        '</span>' +
                                    '</button>' +
                                '</div>')
                            );
                        $alertboxLogin
                            .find(".txt_alert")
                            .html(
                                '<div class="box_wishlist wishAlert">' +
                                    resp.result.error_message +
                                '</div>'
                            );

                    }

                    $('#abox .form_submit').css('display', 'none');
                    $alertBox = $alertboxLogin;
                }

                closeMultiShad('lightbox_achat_express');
                $alertboxLogin.addClass("wishConnection");
                openMultiShad('abox');

            } else {
                $("span", btn).html(resp.result.button_label);
                btn.removeClass("addToWishlistButton").addClass(
                    "existToWishlistButton"
                );
                btn.attr("onclick", "");
                if (typeof isWeddingSpace == "undefined") {
                    btn.attr("href", resp.result.button_link);
                }

                wishlist.push(resp.result.wishlist_row_array);
                addToWishlistUpdateHeader(resp.result.wishlist_size);
            }
        }
    )
}

function moveProductFromWishlistToCart(elemBtn = false, selectedProducts = {}) {

    let datas = {};

    if (elemBtn) {
        let prodId = $(elemBtn).attr("data-produit-id");
        let colorId = $(elemBtn).attr("data-color-id");
        let sizeId = $(elemBtn).attr("data-size-id");

        datas.data = $(elemBtn).attr("data-id");

        if (prodId !== undefined) {
            datas.productId = prodId;
        }

        if (colorId !== undefined) {
            datas.colorId = colorId;
        }

        if (sizeId !== undefined) {
            datas.sizeId = sizeId;
        }
    } else {
        datas = selectedProducts;
    }


    $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), datas, function(resp) {
        if (resp === "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

/**
 * Affiche la lighbox de la wishlist, dynamiquement si la box n'existe pas
 * Utilisation : wishlist_index.php
 */ 
function showsendwishlistmodal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}



/**
 * Envoie le formulaire de la lightbox wishlistavec gestion des erreurs
 * Utilisation : wishlist_index.php
 */
function sendWishListForm() {
    $("#sendWishListSubmit").css({display: "none"});
    $("#wrapper_loader_send").css({display: "block"});
    var content_id = $("#send_wishlist_ajax");
    var content;
    if (
        $("#sendWishListToMails").length &&
        $("#sendWishListToMessage").length &&
        $("#sendWishListYourName").length &&
        $("#sendWishListYourMail").length
    ) {
        var to_mails = $("#sendWishListToMails").val();
        var to_message = $("#sendWishListToMessage").val();
        var your_name = $("#sendWishListYourName").val();
        var your_mail = $("#sendWishListYourMail").val();
        var token_security = $("#token_security_wishlist").val();
        var captcha = $("#g-recaptcha-response").val();

        $.ajax({
          url:
            path_relative_root +
            create_link("ajax_send_wishlist_form") +
            "?to_mails=" +
            to_mails +
            "&to_message=" +
            to_message +
            "&your_name=" +
            your_name +
            "&your_mail=" +
            your_mail +
            "&token_security=" +
            token_security +
            "&g-recaptcha-response=" +
            captcha
        }).done(function(response) {
          $(".zone_text").css({ border: "" });

          if (response.split(",")[0] == "ok") {
            var s_adresse = response.substr(3);

            var sing =
              response.split(",").length > 2
                ? "sendfriend_ok_plur"
                : "sendfriend_ok_sing";

            content =
              "<div>" +
              ajax_file(
                path_relative_root +
                  create_link("ajax_sprintf") +
                  "?arg1=" +
                  sing
              ) +
              "</div>";
            content +=
              '<p style="margin-top: 10px;margin-bottom: 30px;padding:0;text-align:center;">' +
              s_adresse +
              "</p>";
          }
          if (response == "1" || response == "2") {
            content =
              '<p class="txt_err_sendfriend">' +
              ajax_file(
                path_relative_root +
                  create_link("ajax_sprintf") +
                  "?arg1=sendfriend_error_mail_friends_invalid"
              ) +
              "</p>";
            $("#sendWishListToMails").addClass("inputErr");
          } else {
            $("#sendWishListToMails").removeClass("inputErr");
          }
          if (response == "3") {
            content =
              '<p class="txt_err_sendfriend">' +
              ajax_file(
                path_relative_root +
                  create_link("ajax_sprintf") +
                  "?arg1=sendfriend_error_no_message"
              ) +
              "</p>";
            $("#sendWishListToMessage").addClass("inputErr");
          } else {
            $("#sendWishListToMessage").removeClass("inputErr");
          }
          if (response == "4") {
            content =
              '<p class="txt_err_sendfriend">' +
              ajax_file(
                path_relative_root +
                  create_link("ajax_sprintf") +
                  "?arg1=sendfriend_error_no_name"
              ) +
              "</p>";
            $("#sendWishListYourName").addClass("inputErr");
          } else {
            $("#sendWishListYourName").removeClass("inputErr");
          }
          if (response == "5" || response == "6") {
            $("#sendWishListYourMail").addClass("inputErr");

            if (response == "5") {
              content =
                '<p class="txt_err_sendfriend">' +
                ajax_file(
                  path_relative_root +
                    create_link("ajax_sprintf") +
                    "?arg1=sendfriend_error_no_my_mail"
                ) +
                "</p>";
            } else {
              content =
                '<p class="txt_err_sendfriend">' +
                ajax_file(
                  path_relative_root +
                    create_link("ajax_sprintf") +
                    "?arg1=sendfriend_error_my_mail_invalid"
                ) +
                "</p>";
            }
          } else {
            $("#sendWishListYourMail").removeClass("inputErr");
          }

          if (response == "token_error") {
            content =
              '<p class="txt_err_sendfriend">' +
              ajax_file(
                path_relative_root +
                  create_link("ajax_sprintf") +
                  "?arg1=error_token_security"
              ) +
              "</p>";
          }

          if (response.split(",")[0] == "7") {
            var s_adresse = response.substr(3);
            var sing =
              response.split(",").length > 2
                ? "sendfriend_no_ok_plur"
                : "sendfriend_no_ok_sing";
            content =
              "<div>" +
              ajax_file(
                path_relative_root +
                  create_link("ajax_sprintf") +
                  "?arg1=" +
                  sing
              ) +
              "</div>";
          }
          if (
            response.split(",")[0] == "7" ||
            response.split(",")[0] == "token_error" ||
            response.split(",")[0] == "ok"
          ) {
            content_id = $("#sendfriend_alert_mail");

            $("#sendfriend_alert_mail").css({
              display: "block",
              opacity: 0
            });
            $("#wrapper_sendwishlist")
              .addClass("sended")
              .css({ display: "block", opacity: 1 })
              .animate({ opacity: 0 }, function() {
                $("#wrapper_sendwishlist").css({ display: "none" });
                $("#sendfriend_alert_mail").animate(
                  { opacity: 1 },
                  function() {
                    $("#sendfriend_alert_mail").css({
                      display: "block"
                    });
                  }
                );
              });
          } else {
            $("#sendfriend_form_buttons").removeClass("loading");
          }

          content_id.html(content).css({ display: "block" });
        });
    }

    return false;
}

function remove_product_from_wishlist(datas, callback) {
    $.post(path_relative_root + create_link("ajax_remove_from_wishlist"), datas, callback);
}

function ajax_RemoveWishlistProd(elemBtn) {

    var prodId = $(elemBtn).attr("data-produit-id");
    var colorId = $(elemBtn).attr("data-color-id");
    var sizeId = $(elemBtn).attr("data-size-id");

    let datas = {
        id: $(elemBtn).attr("data-id"),
        remove: true
    };

    if (prodId !== undefined) {
        datas.product_id = prodId;
    }

    if (colorId !== undefined) {
        datas.color_id = colorId;
    }

    if (sizeId !== undefined) {
        datas.size_id = sizeId;
    }

    remove_product_from_wishlist(datas, function(data) {
        if (data.result) {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}